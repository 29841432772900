import { EListingCategory, EListingRequiredOfferType } from "@/enums/listing-enums";
import { authedFetch } from "@/utils/authed-fetch";
import { useAuth, useUser } from "@clerk/clerk-react";
import { cilPlus, cilWarning } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useEnrollMutation } from "../../hooks/use-enroll-mutation";
import { useDeleteMyEnrollmentMutation } from "../../hooks/use-delete-my-enrollment-mutation";
import { EListingStatus } from "@/enums/listing-enums";
import { EEnrollmentStatus } from "@/enums/enrollment-enums";
import { useMemo } from "react";
import { StepInProcessEnrollment } from "../../components/step-in-process-enrollment";
import { useAppDispatch } from "@/store/hooks";
import { setModalConfirmation } from "@/store/modal/slice";
import { useBreadcrumbs } from "@/hooks/use-breadcrumbs";
import { SlideAppearWrapper } from "../../components/slide-appear-wrapper";
import { PageDiscoverShowButtons } from "../components/page-discover-show-buttons";
import { AttachmentTable } from "@/components/attachments/attachment-table/attachment-table";
import { integerToFilesize } from "@/utils/integer-to-filesize";
import { dateToYearMonthDay } from "@/utils/date-fns";
import { useAppTranslation } from "@/locales/helpers/translation-helpers";
import { CBadge, CButton, CTooltip } from "@coreui/react";
import { EOfferStatus } from "@/enums/offer-enums";
import { DiscoverShowHeader } from "./components/discover-show-header";
import { DiscoverShowOffersTable } from "./components/discover-show-offers-table";
import { ListingViewSpecifications } from "../../../office-user/listings/view/components/listing-view-specifications";
import { TSpecification } from "../../../office-user/listings/types/listing-types";
import { OfferTypeBadge } from "@/components/badges/listing-offer-type-badge";


export type TDiscoverShowQuery = {
  id: number;
  title: string;
  created_at: string;
  created_by: string;
  status: EListingStatus;
  category: EListingCategory;
  Specifications: TSpecification[];
  is_hidden: 0 | 1;
  budget: number;
  deadline: string;
  setup_id: number;
  region: string;
  country: string;
  enrollment_status: EEnrollmentStatus;
  enrollment_id: number;
  requiredOfferType: EListingRequiredOfferType;
  offer_id: number | null;
  latest_offer_status: EOfferStatus | EOfferStatus.NO_OFFER;
  ListingAttachments?: {
    attachment_url: string;
    display_name: string;
    size: number;
    created_at: string;
  }[];
};

type TDiscoverShowOffersQuery = {
  count: number;
  offers: {
    id: number;
    status: EOfferStatus;
    enrollment_id: number;
    created_by: string;
    created_at: string;
    attachment_count: number;
  }[];
};

// @ts-ignore
const endpoint = import.meta.env.VITE_API_ENDPOINT;

export const PageDiscoverShow = () => {
  const { listingId = "0" } = useParams();
  const { t } = useAppTranslation();
  useBreadcrumbs("DISCOVER_SHOW");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { getToken } = useAuth();

  const {
    data: dataDiscoverShowOffers,
    isLoading: isLoadingDiscoverShowOffers,
    refetch: refetchDiscoverShowOffers,
  } = useQuery<TDiscoverShowOffersQuery>({
    queryKey: [`discover-show-offers`, listingId],
    queryFn: async ({ queryKey }) => {
      const [_, listingId] = queryKey;
      if (!listingId) return null;
      return authedFetch({
        endpoint: `api/provider/enrollment/${listingId}/all-offers`,
        token: await getToken(),
      }).then((res) => res.json());
    },
  });

  const {
    data: dataDiscoverShow,
    isLoading: isLoadingDiscoverShow,
    refetch: refetchDiscoverShow,
  } = useQuery<TDiscoverShowQuery>({
    queryKey: [`discover-show`, listingId],
    queryFn: async ({ queryKey }) => {
      const [_, listingId] = queryKey;
      if (!listingId) return null;
      return authedFetch({
        endpoint: `api/provider/discover/preview/${listingId}`,
        token: await getToken(),
      }).then((res) => res.json());
    },
  });

  const isEnrollment = useMemo(
    () => !!dataDiscoverShow?.enrollment_status,
    [dataDiscoverShow?.enrollment_status]
  );

  const goBack = () => navigate(-1);

  const deleteMutation = useDeleteMyEnrollmentMutation({
    onSuccess: goBack,
    onError: goBack,
  });

  const enrollMutation = useEnrollMutation({
    onSuccess: goBack,
  });

  const handleDelete = () => {
    if (!dataDiscoverShow?.id) return;
    deleteMutation.mutate({ id: dataDiscoverShow.id });
  };

  const handleParticipate = () => {
    if (!dataDiscoverShow?.id) return;
    dispatch(
      setModalConfirmation({
        isOpen: true,
        title: t("Modals.ParticipationConfirmation.title"),
        message: t("Modals.ParticipationConfirmation.message"),
        messageSecondary: `#${dataDiscoverShow?.id}: ${dataDiscoverShow?.title}`,
        buttons: {
          cancel: {
            label: t("CommonTables.cancel"),
            color: "dark",
            variant: "outline",
          },
          confirm: {
            label: t("Modals.ParticipationConfirmation.Buttons.confirm"),
            color: "info",
            className: "text-white",
            onClick: () => enrollMutation.mutate({ id: dataDiscoverShow.id }),
          },
        },
      })
    );
  };

  const handleAddOffer = () => {
    if (!!dataDiscoverShow?.offer_id) return;
    navigate(
      `/discover/${listingId}/offers/${dataDiscoverShow?.enrollment_id}/create`
    );
  };

  const { } = useAuth();
  const { user } = useUser();

  const isMyOwn = dataDiscoverShow?.created_by === user?.id;

  return (
    <div className="border border-gray-300 rounded-lg p-4">
      {isMyOwn && (
        <CBadge color="warning" className="animate-pulse">
          {t("PageDiscoverShow.myOwnListing")}
        </CBadge>
      )}

      <div className={isMyOwn ? "border-2 border-orange-300 rounded p-4" : ""}>
        <DiscoverShowHeader
          data={dataDiscoverShow}
          isMyOwn={isMyOwn}
          isLoading={isLoadingDiscoverShow}
        />

        {isEnrollment && (
          <SlideAppearWrapper
            isLoaded={!isLoadingDiscoverShow && !!dataDiscoverShow}
          >
            <h5 className="px-2 pt-4 pb-0">
              {t("PageDiscoverShow.enrollmentStatus")}
            </h5>
            <StepInProcessEnrollment
              status={dataDiscoverShow?.latest_offer_status}
            />
          </SlideAppearWrapper>
        )}

        {isEnrollment ? (
          <>
            <div className="inline-block pt-4 pb-4 min-w-52">
              <CTooltip
                content={
                  dataDiscoverShow?.offer_id
                    ? t("PageDiscoverShow.Tooltip.addOffer__submitted")
                    : t("PageDiscoverShow.Tooltip.addOffer")
                }
              >
                <span>
                  {!dataDiscoverShow?.offer_id && (
                    <p className="mb-4 text-xl font-semibold text-red-700">
                      <CIcon icon={cilWarning} /> {t("PageDiscoverShow.OffersTable.noOffersMessage")}
                    </p>)}
                  <CButton
                    color="primary"
                    className={`!flex items-center justify-center w-full gap-1 ${!dataDiscoverShow?.offer_id ? "animate-bounce" : ""}`}
                    onClick={handleAddOffer}
                    disabled={!!dataDiscoverShow?.offer_id}
                  >
                    <CIcon icon={cilPlus} />
                    <span>{t("PageDiscoverShow.Buttons.addOffer")}</span>
                  </CButton>
                </span>
              </CTooltip>
            </div>
            {dataDiscoverShowOffers?.offers.length ? (
              <DiscoverShowOffersTable
                offers={dataDiscoverShowOffers?.offers || []}
                isLoading={isLoadingDiscoverShowOffers}
                refetch={() => {
                  refetchDiscoverShowOffers();
                  refetchDiscoverShow();
                }}
              />
            ) : null}
          </>
        ) : null}

        <h3 className="px-2 pt-4 pb-0">{t("PageDiscoverShow.listing_info")}</h3>

        <p className="px-2 pt-[6px] pb-[4px] h-[38px] text-xl">
          <OfferTypeBadge offerType={dataDiscoverShow?.requiredOfferType as EListingRequiredOfferType} />
        </p>

        <h5 className="px-2 pt-4 pb-0">{t("PageDiscoverShow.specifications")}</h5>
        <ListingViewSpecifications
          valueSpecifications={dataDiscoverShow && dataDiscoverShow?.Specifications}
          isEditing={false}
          isProvider={true}
          isLoading={isLoadingDiscoverShow}
        />


        <h5 className="px-2 pt-4 pb-0">{t("PageDiscoverShow.attachments")}</h5>
        <AttachmentTable
          attachments={(dataDiscoverShow?.ListingAttachments || []).map(
            (file) => {
              const splitName = file.display_name.split(".");
              const type = splitName[splitName.length - 1] || "";
              // const fileUrl = `${endpoint}/api/${file.attachment_url}`;
              const fileUrl = file.attachment_url;

              return {
                fileUrl,
                name: file.display_name,
                size: integerToFilesize(file.size),
                type,
                uploadedAt: dateToYearMonthDay(file.created_at),
                markedToDelete: false,
                indexOfNew: null,
                actions: null,
              };
            }
          )}
          isEditing={false}
          isEquipmentProvider={true}
        />

        <div className="flex justify-end gap-2 pt-4">
          <PageDiscoverShowButtons
            goBack={goBack}
            latestOfferStatus={dataDiscoverShow?.latest_offer_status}
            createdBy={dataDiscoverShow?.created_by}
            isEnrollmentInitiated={isEnrollment}
            handleDelete={handleDelete}
            handleParticipate={handleParticipate}
            enrollmentStatus={dataDiscoverShow?.enrollment_status}
          />
        </div>
      </div>
    </div>
  );
};
